<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <div class="clearfix">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" @submit.native.prevent>
              <el-form-item label="企业名称">
                <el-input @change="onQuerySubmit" v-model="query.name" placeholder="请输入企业名称"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit">查询</el-button>
                <el-button type="primary" @click="customCreate" v-if="permission.apiOutletEquipmentCreate">添加排口</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <el-row>
          <el-col :span="24">
            <el-table :data="tableData" stripe style="width: 100%">
              <el-table-column prop="name" label="排口名称" width="150">
              </el-table-column>
              <el-table-column prop="mn" label="设配联网MN" width="180">
              </el-table-column>
              <el-table-column prop="model_name" label="数采型号" width="120">
              </el-table-column>
              <el-table-column prop="model_number" label="数采编号" width="120">
              </el-table-column>
              <el-table-column prop="monitor_name" label="监测类型" width="120">
              </el-table-column>
              <el-table-column prop="" label="运维方式" width="120">
                <template slot-scope="scope">
                    {{scope.row.operation_way === 1 ? '正常' : '售后服务'}}
                </template>
              </el-table-column>
              <el-table-column prop="enterprise_name" label="企业名称" min-width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="address" label="详细地址" min-width="280" show-overflow-tooltip>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="180">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="ConnectDrawer = true; ConnectItem(scope.row)" v-if="permission.apiOutletEquipmentConnectUpdate">更新联网信息</el-button>
                  <el-button @click="customDatail(scope.row)" type="text" size="small">详情</el-button>
                  <el-button type="text" size="small" @click="deleteItem(scope.row)" v-if="permission.apiOutletEquipmentDelete">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" background @current-change="changeCurrent" :current-page="query.page" :total="total"> </el-pagination>
      </div>
    </el-card>
    <!-- 更新折算状态 -->
    <el-drawer title="更新联网信息" :visible.sync="ConnectDrawer" direction="rtl" :wrapperClosable="false" size="500px">
      <div class="updatascoll">
        <el-card class="box-card">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <el-form label-width="120px">
              <ValidationProvider rules="required|Chinese" name="设备联网MN" v-slot="{ errors }">
                <el-form-item required :error="errors[0]" label="设备联网MN">
                  <el-input v-model="res.mn"></el-input>
                </el-form-item>
              </ValidationProvider>
              <!-- <ValidationProvider rules="required" name="设备联网密码" v-slot="{ errors }">
                <el-form-item :error="errors[0]" label="设备联网密码">
                  <el-input v-model="ConnectUpdata.password" show-password></el-input>
                </el-form-item>
              </ValidationProvider> -->
              <el-form-item>
                <el-button type="primary" @click="handleSubmit(connectUpdata)">确认更新</el-button>
              </el-form-item>
            </el-form>
          </ValidationObserver>
        </el-card>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { apiOutletEquipmentList, apiOutletEquipmentDelete, apiOutletEquipmentConnectUpdate, apiOutletEquipmentDetail } from "src/api/index";
import md5 from 'js-md5';
export default {
  components: {},
  data() {
    return {
      loading: false,
      ConnectDrawer: false,
      ConnectUpdata:{
        id: 0,
        mn: null,
      },
      query: {
        name: '',
        page: 1,
        logotype: 'basicEquipment'
      },
      res: {},
      tableData: [],
      total: 10,
    };
  },
  mounted() {
    const _this = this;
    let query = JSON.parse(_this.$localStorage.get(md5('query')))
    if (query === null) {
      _this.query = _this.query
    } else if (query.logotype === 'basicEquipment') {
      _this.query =  query;
    } else {
      _this.$localStorage.remove(md5("query"));
    }
    _this.changeCurrent(_this.query.page);
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    url: (state) => state.url,
    permission: (state) => state.permission,
  }),
  watch: {
    '$route': 'getData'
  },
  methods: {
    ...mapActions(["pageInit"]),
    //查询排口数据
    onQuerySubmit() { 
      this.query.page = 1
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&name=' + this.query.name;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getData();
      } else {       
        this.$router.replace(route);
      }
    },
    //跳转添加排口
    customCreate() {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
       this.$router.push('/admin/basic/equipment-add')
    },
    //跳转排口详情
    customDatail(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push('/admin/basic/equipment-detail/detail/' + item.id)
    },
    //删除排口信息
    deleteItem(item) {
      const _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        _this.pageInit(_this).then(() => {
          apiOutletEquipmentDelete({id:item.id})
          .then((res) => {
            _this.$message({
              message: "删除成功",
              type: "success",
            });
            _this.onQuerySubmit();
          })
          .catch((err) => {
            _this.error(err);
          });
        });  
      })
    },
    //获取排口联网信息
    ConnectItem(item) {
      const _this = this;
      _this.res.mn = item.mn;
      _this.ConnectUpdata.mn = item.mn;
      apiOutletEquipmentDetail({id: item.id})
			.then((detail) => {
        _this.res = JSON.parse(JSON.stringify(detail));
			})
			.catch((err) => {
				_this.$message.error(err.message);
			});
    },
    //更新联网信息
    connectUpdata() {
      const _this = this;
      apiOutletEquipmentConnectUpdate(_this.res)
      .then((res) => {
        _this.ConnectDrawer = false
        _this.$message({
          message: "更新成功",
          type: "success",
        });
        _this.onQuerySubmit();
      })
      .catch((err) => {
        _this.error(err);
      })
    },
    //切换分页
    changeCurrent(item) {
      this.query.page = item;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&name=' + this.query.name;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getData();
      } else {       
        this.$router.replace(route);
      }
    },
    //获取排口列表数据
    getData() {
      const _this = this;
      _this.loading = true;
      apiOutletEquipmentList(_this.query)
      .then((lists) => {
        _this.loading = false;
        _this.tableData = lists.data;
        _this.total = lists.total;
      })
      .catch((err) => {
        _this.loading = false;
        _this.error(err);
      });
    },
    //错误提示
    error(err) {
      if (err.message === '没有操作权限') {
        return
      } else {         
        this.$message.error(err.message);
      } 
    }
  },
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
  position: relative;
  margin-top: 7px;
}
.updatascoll {
  overflow: auto;
  height: 860px;
}
</style>